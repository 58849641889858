import { growth } from '~/utils/api.ts';

const model = {
  namespace: 'allowance',
  /**
   * 知识列表
   * @params {Object} state - 参数
   * @params {number} state.list - 知识列表
   * @params {number} state.cid - 当前显示的知识ID
   */
  state: {
    info: null,
    rewardList: [],
  },
  effects: {
    *getUserInfo({ payload, callback }, { call, put }) {
      const response = yield call(growth.getUserInfo, payload);
      if (response.status !== 0) {
        return;
      }
      const { data } = response;
      yield put({
        type: 'updateUser',
        payload: {
          info: data.growth_info,
        },
      });
      if (callback) {
        callback();
      }
    },
    *getRewardHistory({ payload }, { call, put }) {
      const response = yield call(growth.getRewardHistory, payload);
      if (response.status !== 0) {
        return;
      }
      const { data } = response;
      yield put({
        type: 'updateList',
        payload: {
          rewardList: data.rewarded_history,
        },
      });
    },

    *setCurrent({ payload }, { put }) {
      yield put({
        type: 'updateCurrent',
        payload,
      });
    },
  },
  reducers: {
    updateUser(state, action) {
      const {
        payload: { info },
      } = action;
      return {
        ...state,
        info,
      };
    },
    updateList(state, action) {
      const {
        payload: { rewardList },
      } = action;
      return {
        ...state,
        rewardList,
      };
    },
    updateCurrent(state, action) {
      const {
        payload: { current },
      } = action;
      return {
        ...state,
        current,
      };
    },
  },
};

export default model;

import { city } from '~/utils/api.ts';

const model = {
  namespace: 'city',
  state: {
    cityList: [],
    currentCity: {},
  },
  reducers: {
    init(state, { payload }) {
      const { cityList, currentCity } = payload;
      return {
        ...state,
        cityList,
        currentCity,
      };
    },
    select(state, { payload }) {
      const { currentCity } = payload;
      return { ...state, currentCity };
    },
  },
  effects: {
    *getCity(_, { call, put, select }) {
      const ip = yield select(state => state.app.ip);
      const resp = yield call(city.getCity, { ip });
      if (resp.status === 0) {
        const cityList = resp.all_city;
        const currentCity = resp.city;
        yield put({
          type: 'init',
          payload: {
            cityList,
            currentCity,
          },
        });
      }
    },
    *updateCity({ payload }, { put }) {
      yield put({
        type: 'select',
        payload,
      });
      yield put({ type: 'index/getSku' });
    },
  },
};

export default model;

import { DEVICE_TYPE } from '~/utils/constant';

const model = {
  namespace: 'app',
  state: {
    ip: '',
    device: DEVICE_TYPE.DESKTOP,
  },
  reducers: {
    saveIp(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveDeviceType(state, { payload }) {
      return {
        ...state,
        device: payload,
      };
    },
  },
};

export default model;

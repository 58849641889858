import { recruit } from '~/utils/api.ts';

const model = {
  namespace: 'recruitShare',
  state: {
    id: null,
    content: null,
  },
  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *getContentsShare({ payload, callback }, { call, put }) {
      const resp = yield call(recruit.getContentsShare, payload);
      if (resp.status === 0) {
        yield put({
          type: 'setState',
          payload: { content: resp.data },
        });
        if (callback) {
          callback();
        }
      }
    },
  },
};

export default model;

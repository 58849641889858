import Router from 'next/router';
import { accounts } from '~/utils/api.ts';
import { API_COOKIE_NAME, WEB_COOKIE_NAME } from '~/utils/constant';
import { getCookie } from './cookie';
import { isBrowser } from './util';
import { data as apiData } from './request.ts';

const loginPathPretected = [
  '/demo/test',
  '/user',
  '/user/event/address',
  '/user/event/online',
  '/user/knowledge',
  '/user/allowance',
  '/crm/salesman/[id]/clue/[clueid]',
  '/group/guide',
  '/group/join',
  '/assistant',
  '/acquisition/offline',
  '/acquisition/online',
  '/beemom/user/event/[id]',
  '/beemom/user/event/[id]/[name]',
  '/nurse/interview/[id]',
  '/nurse/comment/new',
];

const logoutPathPretected = ['/accounts/login'];

/**
 * 进入系统初始化函数，用于用户授权相关
 * @param {Object} ctx
 */
export default async function(ctx) {
  const { pathname, res, req, dvaStore } = ctx;
  let cookie = '';
  if (!isBrowser) {
    // 服务器渲染时，传递浏览器 cookie
    cookie = req.headers.cookie;
    apiData.cookie = cookie || '';
  }
  const userToken = getCookie(API_COOKIE_NAME, req);
  const webToken = getCookie(WEB_COOKIE_NAME, req);
  apiData.token = userToken || '';
  const hasToken = userToken || webToken;
  if (hasToken && !dvaStore.getState().auth.token) {
    const resp = await accounts.getUserInfo();
    if (resp.status === 0) {
      const payload = {
        token: userToken,
        user: resp.data,
      };
      await dvaStore.dispatch({
        type: 'auth/login',
        payload,
      });
    }
  }

  const { login } = dvaStore.getState().auth;
  let nextUrl = isBrowser
    ? `${window.location.pathname}${window.location.search}`
    : req.url;
  nextUrl = encodeURIComponent(nextUrl);
  const loginRedirect = `/accounts/login?next=${nextUrl}`;
  if (!login && loginPathPretected.includes(pathname)) {
    // 未登录时，访问受限路由，进行重定向
    if (isBrowser) {
      Router.push(loginRedirect);
    } else {
      res.writeHead(302, {
        Location: loginRedirect,
      });
      res.end();
    }
    return;
  }
  if (login && logoutPathPretected.includes(pathname)) {
    // 已登录时，访问受限路由，进行重定向
    console.log('\nneedlogout');
  }
}

import { category } from '~/utils/api.ts';

const model = {
  namespace: 'category',
  /**
   * 品类详情页面
   * @params {Object} state - 参数
   * @params {number} state.status - 品类ID接口状态码
   * @params {number} state.id - 品类ID
   * @params {number} state.type - 品类ID类型 1：一级标签，2:二级标签
   * @params {string} state.type - 品类ID类型string
   * @params {string} state.sort - 排序参数 {sold_num=销量最高、score=评分最高}
   * @params {Object} state.categorys - 品类数据
   * @params {Object} state.targetCategory - 当前选中的品类数据
   * @params {Object} state.list - 商品列表
   * @params {number} state.page - 商品列表页数
   * @params {number} state.total - 商品列表总页数
   * @params {number} state.totalItems - 商品列表总条数
   * @params {string} state.showType - 用于区分当前品类列表展示sku还是机构
   */
  state: {
    showType: 'product',
    status: 0,
    id: '',
    type: '',
    typeStr: 'pc_index_categorys',
    sort: 'sold_num',
    categorys: {},
    targetCategory: {},
    list: [],
    page: 1,
    total: 1,
    totalItems: 1,
    shopList: [],
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *init({ payload }, { put, call, select }) {
      const { type } = payload;
      if (type === 2) {
        payload.typeStr = 'categorys';
      } else if (type === 1) {
        payload.typeStr = 'pc_index_categorys';
      }
      yield put({
        type: 'setState',
        payload,
      });
      const categoryId = yield select(state => state.category.id);
      const typeStr = yield select(state => state.category.typeStr);
      const cityCode = yield select(state => state.city.currentCity.id);
      const resp = yield call(category.getCategory, {
        category_id: categoryId,
        type: typeStr,
        city_code: cityCode,
      });
      if (resp.status === 0) {
        const categorys = resp.pc_index_category;
        const targetCategory = resp.target_category;
        yield put({
          type: 'setState',
          payload: {
            categorys,
            targetCategory,
            showType: targetCategory.show_type || 'product',
          },
        });
      } else {
        const { status } = resp;
        yield put({
          type: 'setState',
          payload: {
            status,
          },
        });
      }
    },
    *selectSort({ payload, callback }, { put }) {
      yield put({
        type: 'setState',
        payload,
      });
      yield put({ type: 'getDataList' });
      if (callback) {
        callback();
      }
    },
    *getSku(_, { call, put, select }) {
      const categoryId = yield select(
        state => state.category.targetCategory.id,
      );
      const typeStr = yield select(state => state.category.typeStr);
      const page = yield select(state => state.category.page);
      const sort = yield select(state => state.category.sort);
      const cityCode = yield select(state => state.city.currentCity.id);
      const resp = yield call(category.getSku, {
        category_id: categoryId,
        type: typeStr,
        sort_column: sort,
        page,
        city_code: cityCode,
      });
      if (resp.status === 0) {
        const total = resp.page_info.total_pages || 1;
        const totalItems = resp.page_info.total_items || 1;
        const list = resp.products;
        yield put({
          type: 'setState',
          payload: {
            total,
            list,
            totalItems,
          },
        });
      }
    },
    *getShopList(_, { call, put, select }) {
      const categoryId = yield select(
        state => state.category.targetCategory.id,
      );
      const typeStr = yield select(state => state.category.typeStr);
      const page = yield select(state => state.category.page);
      const sort = yield select(state => state.category.sort);
      const cityCode = yield select(state => state.city.currentCity.id);
      const resp = yield call(category.getShopList, {
        category_id: categoryId,
        type: typeStr,
        sort_column: sort === 'score' ? 3 : 2,
        page,
        city_code: cityCode,
      });
      if (resp.status === 0) {
        const { data } = resp;
        const total = data.page_info.total_pages || 1;
        const totalItems = data.page_info.total_items || 1;
        const shopList = data.items || [];
        yield put({
          type: 'setState',
          payload: {
            total,
            shopList,
            totalItems,
          },
        });
      }
    },
    *getDataList(_, { put, select }) {
      const showType = yield select(state => state.category.showType);
      if (showType === 'product') {
        yield put({ type: 'getSku' });
      } else {
        yield put({ type: 'getShopList' });
      }
    },
  },
};

export default model;

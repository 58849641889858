import App from 'next/app';
import React from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';
import parser from 'ua-parser-js';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import '~/styles/nprogress.css';
import { isDev } from '~/utils/util';
import initialize from '~/utils/initialize';
import WithDva from '~/utils/store';
import { DEVICE_TYPE } from '~/utils/constant';
import { version } from '../../package.json';

Sentry.init({
  dsn: 'https://7f48c207dfde4cd0a645a3f4696eaa9a@sentry.xindebaby.com/7',
  release: version,
  enabled: !isDev,
});

Router.onRouteChangeStart = () => {
  NProgress.start();
};

Router.onRouteChangeComplete = () => {
  NProgress.done();
  if (isDev) {
    // via: https://github.com/zeit/next-plugins/issues/282
    const els = document.querySelectorAll(
      'link[href*="/_next/static/css/styles.chunk.css"]',
    );
    const timestamp = new Date().valueOf();
    els[0].href = `/_next/static/css/styles.chunk.css?v=${timestamp}`;
  }
};

Router.onRouteChangeError = () => {
  NProgress.done();
};

class CustomApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    let ip = '';
    let ua = '';

    initialize(ctx);

    const { dvaStore } = ctx;
    if (ctx.req) {
      ip = ctx.req.headers['x-forwarded-for'];
      ua = ctx.req.headers['user-agent'];
      await dvaStore.dispatch({
        type: 'app/saveIp',
        payload: {
          ip,
        },
      });
    } else {
      ua = navigator.userAgent;
    }

    const userAgent = parser(ua);
    const isMobile = userAgent.device.type === 'mobile';
    let deviceType = DEVICE_TYPE.DESKTOP;
    if (isMobile) {
      deviceType = DEVICE_TYPE.MOBILE;
    }
    await dvaStore.dispatch({
      type: 'app/saveDeviceType',
      payload: deviceType,
    });

    if (dvaStore.getState().city.cityList.length === 0) {
      await dvaStore.dispatch({ type: 'city/getCity' });
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps, ua };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, dvaStore, ua } = this.props;
    return (
      <Provider store={dvaStore}>
        <UserAgentProvider ua={ua}>
          <Component {...pageProps} />
        </UserAgentProvider>
      </Provider>
    );
  }
}

export default WithDva(CustomApp);

import { crm } from '~/utils/api.ts';

const model = {
  namespace: 'salesman',
  state: {
    id: '',
    clueid: '',
    clueParams: {},
    info: {},
    orders: {},
    addFriend: true,
    currentSetp: {}, // 线索当前步骤
    clueStepId: '', // form表单 - 潜在线索
    priority: '', // form表单 - 跟进优先级
    followTime: '', // form表单 - 跟进日期
    stepContent: '', // form表单 - 跟进情况说明
    nextTime: '', // form表单 - 下次沟通日期
    conversionPrice: '', // form表单 - 转化金额
    grossProfit: '', // form表单 - 转化毛利
    clueContent: '', // form表单 - 下次跟进情况说明
    name: '', // 名称
    phone: '', // 联系方式
    homeAddress: '', // 家庭地址
    recordsList: [], // 进店记录
    experienceList: [], // 体验订单列表
    orderId: '', // 当前选中的体验订单id
    shopsList: [], // 门店列表
    shopId: '', // 当前选中门店id
    enterDate: '', // 创建进店记录时间
    type: 1, // 创建进店记录类型 1=预约，2=到店
    recordId: '', // 记录id
    providerList: '', // 商户列表
    providerId: '', // 当前选中的商户id
  },
  reducers: {
    init(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *getParams(_, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const resp = yield call(crm.getParams, { clueId });
      // console.log(resp);
      if (resp.status === 0) {
        const clueParams = resp.data;
        yield put({
          type: 'init',
          payload: {
            clueParams,
          },
        });
      }
    },
    *getInfo(_, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const clueParams = yield select(state => state.salesman.clueParams);
      const propertyList = clueParams.property_list;
      const resp = yield call(crm.getInfo, { clueId });
      // console.log(resp);
      if (resp.status === 0) {
        const info = resp.data;
        const currentSetp = info.current_setp;
        const clueStepId = info.current_setp.id || '';
        const priority =
          info.current_step_priority || propertyList[0].value || '';
        const clueContent = info.content || '';

        const { user } = info;
        const name = user.name || '';
        const phone = user.phone || '';
        const homeAddress = user.home_address || '';
        yield put({
          type: 'init',
          payload: {
            info,
            currentSetp,
            clueStepId,
            priority,
            clueContent,
            name,
            phone,
            homeAddress,
            addFriend: info.add_friend,
          },
        });
      }
    },
    *getOrders(_, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const resp = yield call(crm.getOrders, { clueId });
      // console.log(resp);
      if (resp.status === 0) {
        const orders = resp.data;
        yield put({
          type: 'init',
          payload: {
            orders,
          },
        });
      }
    },
    *editUser({ payload, callback }, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const resp = yield call(crm.editUser, { clue_id: clueId, ...payload });
      // console.log(resp);
      if (resp.status === 0) {
        yield put({ type: 'getInfo' });
      }
      if (callback) {
        callback(resp);
      }
    },
    *editClue({ payload, callback }, { call, put }) {
      const resp = yield call(crm.editClue, { ...payload });
      // console.log(resp);
      if (resp.status === 0) {
        yield put({ type: 'getInfo' });
      }
      if (callback) {
        callback(resp);
      }
    },
    *getRecords(_, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const resp = yield call(crm.getRecords, { clueId });
      // console.log('进店记录');
      // console.log(resp);
      if (resp.status === 0) {
        const recordsList = resp.data;
        yield put({
          type: 'init',
          payload: {
            recordsList,
          },
        });
      }
    },
    *getExperienceOrders(_, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const resp = yield call(crm.getExperienceOrders, { clueId });
      // console.log('体验订单列表');
      // console.log(resp);
      if (resp.status === 0) {
        const experienceList = resp.data;
        const orderId = experienceList[0] ? experienceList[0].id : '';
        yield put({
          type: 'init',
          payload: {
            experienceList,
            orderId,
          },
        });
      }
    },
    *getShops(_, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const orderId = yield select(state => state.salesman.orderId);
      const currentShopId = yield select(state => state.salesman.shopId);
      const experienceList = yield select(
        state => state.salesman.experienceList,
      );

      let id = '';
      let providerId = '';
      yield experienceList.forEach(item => {
        if (item.id === parseInt(orderId, 10)) {
          id = item.shop_id;
          providerId = item.provider_id;
        }
      });

      const payload = { clueId };
      if (id) {
        Object.assign(payload, { id });
      } else {
        yield put({
          type: 'init',
          payload: {
            shopsList: [],
            shopId: '',
          },
        });
        return;
      }

      if (providerId) {
        Object.assign(payload, { provider_id: providerId });
      }

      const resp = yield call(crm.getShops, payload);
      if (resp.status === 0) {
        const shopsList = resp.data;
        const nextPayload = { shopsList };
        // console.log(shopsList);
        let shopId = shopsList[0] ? shopsList[0].id : '';
        yield shopsList.forEach(item => {
          if (item.id === parseInt(currentShopId, 10)) {
            shopId = currentShopId;
          }
        });
        Object.assign(nextPayload, { shopId });
        yield put({
          type: 'init',
          payload: nextPayload,
        });
      }
    },
    *getProviders(_, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const orderId = yield select(state => state.salesman.orderId);
      const currentProviderId = yield select(
        state => state.salesman.providerId,
      );
      const experienceList = yield select(
        state => state.salesman.experienceList,
      );

      let id = '';
      yield experienceList.forEach(item => {
        if (item.id === parseInt(orderId, 10)) {
          id = item.provider_id;
        }
      });

      const payload = { clueId };
      if (id) {
        Object.assign(payload, { id });
      } else {
        yield put({
          type: 'init',
          payload: {
            providerList: [],
            providerId: '',
          },
        });
        return;
      }

      const resp = yield call(crm.getProviders, payload);
      if (resp.status === 0) {
        const providerList = resp.data;
        const nextPayload = { providerList };
        let providerId = providerList[0] ? providerList[0].id : '';
        yield providerList.forEach(item => {
          if (item.id === parseInt(currentProviderId, 10)) {
            providerId = currentProviderId;
          }
        });
        Object.assign(nextPayload, { providerId });
        yield put({
          type: 'init',
          payload: nextPayload,
        });
      }
    },
    *createRecords({ callback }, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const orderId = yield select(state => state.salesman.orderId);
      const type = yield select(state => state.salesman.type);
      const shopId = yield select(state => state.salesman.shopId);
      const providerId = yield select(state => state.salesman.providerId);
      const enterDate = yield select(state => state.salesman.enterDate);
      const payload = {
        clueId,
        type: parseInt(type, 10),
        enter_date: enterDate,
      };
      if (shopId) {
        Object.assign(payload, { shop_id: parseInt(shopId, 10) });
      }
      if (orderId) {
        Object.assign(payload, { order_id: parseInt(orderId, 10) });
      }
      if (providerId) {
        Object.assign(payload, { provider_id: parseInt(providerId, 10) });
      }
      const resp = yield call(crm.createRecords, payload);
      // console.log(resp);
      if (resp.status === 0) {
        yield put({ type: 'getRecords' });
      }
      if (callback) {
        callback(resp);
      }
    },
    *editRecords({ callback }, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const recordId = yield select(state => state.salesman.recordId);
      const orderId = yield select(state => state.salesman.orderId);
      const type = yield select(state => state.salesman.type);
      const shopId = yield select(state => state.salesman.shopId);
      const providerId = yield select(state => state.salesman.providerId);
      const enterDate = yield select(state => state.salesman.enterDate);
      const payload = {
        clueId,
        recordId,
        type: parseInt(type, 10),
        enter_date: enterDate,
      };
      if (shopId) {
        Object.assign(payload, { shop_id: parseInt(shopId, 10) });
      }
      if (orderId) {
        Object.assign(payload, { order_id: parseInt(orderId, 10) });
      }
      if (providerId) {
        Object.assign(payload, { provider_id: parseInt(providerId, 10) });
      }
      const resp = yield call(crm.editRecords, payload);
      // console.log(resp);
      if (resp.status === 0) {
        yield put({ type: 'getRecords' });
      }
      if (callback) {
        callback(resp);
      }
    },
    *deletRecords({ callback }, { call, put, select }) {
      const clueId = yield select(state => state.salesman.clueid);
      const recordId = yield select(state => state.salesman.recordId);
      const payload = {
        clueId,
        recordId,
      };
      const resp = yield call(crm.deletRecords, payload);
      // console.log(resp);
      if (resp.status === 0) {
        yield put({ type: 'getRecords' });
      }
      if (callback) {
        callback(resp);
      }
    },
  },
};

export default model;

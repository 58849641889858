import { growth } from '~/utils/api.ts';

const model = {
  namespace: 'knowledge',
  /**
   * 知识列表
   * @params {Object} state - 参数
   * @params {number} state.list - 知识列表
   * @params {number} state.current - 当前显示的知识ID
   * @params {number} state.isLike - 点击按钮
   * @params {number} state.count - 当天获得奖励次数
   */
  state: {
    list: [],
    current: 0,
    isLike: null,
    count: 0,
    closeLayer: false,
  },
  effects: {
    *getKnowledgeList({ payload, callback }, { call, put, select }) {
      let query = yield select(state => state.query);
      query = {
        ...query,
        ...payload,
      };

      const response = yield call(growth.getKnowledgeList, query);
      if (response.status !== 0) {
        return;
      }
      const { data } = response;
      yield put({
        type: 'updateCount',
        payload: {
          count: data.today_reward_count,
        },
      });
      yield put({
        type: 'updateList',
        payload: {
          list: data.growth_knowledge_list,
        },
      });
      if (callback) callback();
    },
    *setState({ payload }, { put }) {
      yield put({
        type: 'updateState',
        payload,
      });
    },
    *setCount({ payload }, { put }) {
      yield put({
        type: 'updateCount',
        payload,
      });
    },
    *hideLayer(_, { put }) {
      yield put({
        type: 'updateLayer',
      });
    },
    *clearData(_, { put }) {
      yield put({
        type: 'resetState',
      });
    },
  },
  reducers: {
    updateList(state, action) {
      const {
        payload: { list },
      } = action;
      const { list: prevList } = state;
      return {
        ...state,
        list: [...prevList, ...list],
      };
    },
    updateState(state, action) {
      const {
        payload: { current, isLike },
      } = action;
      return {
        ...state,
        current,
        isLike,
      };
    },
    updateCount(state, action) {
      const {
        payload: { count },
      } = action;
      return {
        ...state,
        count,
      };
    },
    updateLayer(state) {
      const closeLayer = true;
      return {
        ...state,
        closeLayer,
      };
    },
    resetState() {
      return {
        list: [],
        current: 0,
        isLike: null,
        count: 0,
        closeLayer: false,
      };
    },
  },
};

export default model;

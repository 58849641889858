import { growth } from '~/utils/api.ts';
import pic1 from '~/static/mobile/user/learning/1.png';
import pic2 from '~/static/mobile/user/learning/2.png';
import pic3 from '~/static/mobile/user/learning/3.png';
import pic4 from '~/static/mobile/user/learning/4.png';
import pic5 from '~/static/mobile/user/learning/5.png';
import pic6 from '~/static/mobile/user/learning/6.png';
import pic7 from '~/static/mobile/user/learning/7.png';
import pic8 from '~/static/mobile/user/learning/8.png';
import pic9 from '~/static/mobile/user/learning/9.png';
import pic10 from '~/static/mobile/user/learning/10.png';

const model = {
  namespace: 'learning',
  /**
   * 知识列表
   * @params {Object} state - 参数
   * @params {number} state.list - 知识列表
   * @params {number} state.current - 当前显示的知识ID
   * @params {number} state.isLike - 点击按钮
   * @params {number} state.count - 当天获得奖励次数
   */
  state: {
    list: [],
    current: 0,
    isLike: null,
    count: 0,
    closeLayer: false,
  },
  effects: {
    *getKnowledgeList({ payload, callback }, { call, put, select }) {
      let query = yield select(state => state.query);
      query = {
        ...query,
        ...payload,
      };

      const response = yield call(growth.getKnowledgeList, query);
      if (response.status !== 0) {
        return;
      }
      const { data } = response;
      yield put({
        type: 'updateCount',
        payload: {
          count: data.today_reward_count,
        },
      });
      yield put({
        type: 'updateList',
        payload: {
          list: [
            {
              id: 1,
              liked: false,
              likes: 209,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/e29516bac24ae7dcec7f5b871994ba601573204791?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 288578,
                nickname: '贝琪',
              },
              content: pic1,
            },
            {
              id: 2,
              liked: false,
              likes: 201,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/31f2d4ef5c22b1e1abcda80f41d434eb1565156413.49?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 157650,
                nickname: '嘀笛哒嗒',
              },
              content: pic2,
            },
            {
              id: 3,
              likes: 400,
              liked: false,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/2b4edf99778169f118fad93a74518eb01567502305.18?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 271906,
                nickname: '媛儿',
              },
              content: pic3,
            },
            {
              id: 4,
              likes: 120,
              liked: false,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/28675d3e2a65df1910baaefd4256cef51572403520?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 199704,
                nickname: '林子',
              },
              content: pic4,
            },
            {
              id: 5,
              likes: 100,
              liked: false,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/e9053fb090ad7b7407ee12bf684970391571876431?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 278910,
                nickname: '✿✿✿',
              },
              content: pic5,
            },
            {
              id: 6,
              likes: 130,
              liked: false,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/7237a469f2a3b6d3dd426b51cfaa3ba41573528487?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 238871,
                nickname: '王立莉',
              },
              content: pic6,
            },
            {
              id: 7,
              likes: 190,
              liked: false,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/920721b7791dff3db1fe4e792fc0bb651561610854.8?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 173455,
                nickname: '🐬徐梓琪Q🐬',
              },
              content: pic7,
            },
            {
              id: 8,
              likes: 230,
              liked: false,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/8149a2903f15bdf9b0b004589d00cc901571380815?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 236505,
                nickname: 'Sylvie 东方',
              },
              content: pic8,
            },
            {
              id: 9,
              likes: 330,
              liked: false,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/0dbfab4cf27dff182b91f947ad5d5add1574143732?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 269094,
                nickname: '。拉小妮',
              },
              content: pic9,
            },
            {
              id: 10,
              likes: 290,
              liked: false,
              user: {
                headimgurl:
                  'http://avatar.img.xindebaby.com/a0a063eba70963bf54a091615ad01d6a1572249848?imageMogr2/auto-orient/thumbnail/!160x160r/gravity/Center/crop/160x160',
                id: 292244,
                nickname: '于小丹',
              },
              content: pic10,
            },
          ],
        },
      });
      if (callback) callback();
    },
    *setList({ payload }, { put }) {
      yield put({
        type: 'updateList',
        payload,
      });
    },
    *setState({ payload }, { put }) {
      yield put({
        type: 'updateState',
        payload,
      });
    },
    *setCount({ payload }, { put }) {
      yield put({
        type: 'updateCount',
        payload,
      });
    },
    *hideLayer(_, { put }) {
      yield put({
        type: 'updateLayer',
      });
    },
    *clearData(_, { put }) {
      yield put({
        type: 'resetState',
      });
    },
  },
  reducers: {
    updateList(state, action) {
      const {
        payload: { list },
      } = action;
      return {
        ...state,
        list: [...list],
      };
    },
    updateState(state, action) {
      const {
        payload: { current, isLike },
      } = action;
      return {
        ...state,
        current,
        isLike,
      };
    },
    updateCount(state, action) {
      const {
        payload: { count },
      } = action;
      return {
        ...state,
        count,
      };
    },
    updateLayer(state) {
      const closeLayer = true;
      return {
        ...state,
        closeLayer,
      };
    },
    resetState() {
      return {
        list: [],
        current: 0,
        isLike: null,
        count: 0,
        closeLayer: false,
      };
    },
  },
};

export default model;

import { institution } from '~/utils/api.ts';

const model = {
  namespace: 'institution',
  /**
   * sku详情页面
   * @params {Object} state - 参数
   * @params {number} state.status - sku接口状态
   * @params {number} state.cid - 品类ID
   * @params {number} state.type - 品类ID类型 1：一级标签，2:二级标签
   * @params {number} state.id - 机构ID
   * @params {number} state.page - sku列表页数
   * @params {number} state.total - sku列表总页数
   * @params {number} state.totalItems - sku列表总条数
   * @params {Array } state.productList - sku列表
   * @params {Object} state.shop - 机构数据
   * @params {Array } state.recommendList - 推荐机构列表
   * @params {Array } state.commentList - 评论列表
   */
  state: {
    status: 0,
    id: '',
    type: '',
    typeStr: '',
    cid: '',
    shop: {},
    recommendList: [],
    page: 1,
    productList: [],
    total: 1,
    totalItems: 0,
    commentList: [],
    comment: {
      page: 1,
      total: 1,
      totalItems: 1,
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *init({ payload }, { put }) {
      const { type, p } = payload;
      if (type && type === 1) {
        payload.typeStr = 'pc_index_categorys';
      } else if (type && type === 2) {
        payload.typeStr = 'categorys';
      }
      if (p) {
        payload.comment = {
          page: p || 1,
          total: 1,
        };
      }
      yield put({
        type: 'setState',
        payload,
      });
    },
    *getShopDetail(_, { call, put, select }) {
      const data = {
        shop_id: yield select(state => state.institution.id),
      };
      // 判断url中是否携带品类id
      const cid = yield select(state => state.institution.cid);
      if (cid) {
        data.category_id = cid;
        data.category_type = yield select(state => state.institution.typeStr);
      }
      const resp = yield call(institution.getShopDetail, data);
      if (resp.status === 0) {
        const shop = resp.data || {};
        yield put({
          type: 'setState',
          payload: {
            shop,
          },
        });
      } else {
        const { status } = resp;
        yield put({
          type: 'setState',
          payload: {
            status,
          },
        });
      }
    },
    *getRecommendList(_, { call, put, select }) {
      const { id, cid } = yield select(state => state.institution);
      const payload = {
        shop_id: id,
      };
      if (cid) {
        Object.assign(payload, { category_id: cid });
      }
      const resp = yield call(institution.getRecommendList, payload);
      if (resp.status === 0) {
        const { data } = resp;
        yield put({
          type: 'setState',
          payload: {
            recommendList: data || [],
          },
        });
      }
    },
    *getProductList(_, { call, put, select }) {
      const { productList } = yield select(state => state.institution);
      const payload = {
        shop_id: yield select(state => state.institution.id),
        page: yield select(state => state.institution.page),
      };
      const resp = yield call(institution.getProductList, payload);
      if (resp.status === 0) {
        const { data } = resp;
        const total = data.page_info.total_pages;
        const totalItems = data.page_info.total_items;
        let newArr = productList;
        if (data.items && data.items.length) {
          newArr = newArr.concat(data.items);
        }
        yield put({
          type: 'setState',
          payload: {
            productList: newArr,
            total,
            totalItems,
          },
        });
      }
    },
    *upProductList({ payload }, { put }) {
      yield put({
        type: 'setState',
        payload,
      });
      yield put({ type: 'getProductList' });
    },
    *getCommentList(_, { call, put, select }) {
      const { comment } = yield select(state => state.institution);
      const { page } = comment;
      const payload = {
        shop_id: yield select(state => state.institution.id),
        page,
      };
      const resp = yield call(institution.getCommentList, payload);
      if (resp.status === 0) {
        const { data } = resp;
        yield put({
          type: 'setState',
          payload: {
            commentList: data.items || [],
            comment: {
              page,
              total: data.page_info.total_pages || 1,
              totalItems: data.page_info.total_items || 1,
            },
          },
        });
      }
    },
  },
};

export default model;

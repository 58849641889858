import app from './app';
import auth from './auth';
import homepage from './homepage';
import city from './city';
import category from './category';
import tuan from './tuan';
import institution from './institution';
import edi from './edi';
import knowledge from './user/knowledge';
import allowance from './user/allowance';
import learning from './user/learning';
import salesman from './crm/salesman';
import groupGuide from './group/guide';
import book from './book';
import recruitShare from './recruit/share';

// 数组中第一项后面添加注释，强制数组分行 https://github.com/prettier/prettier-vscode/issues/352
const model = [
  app, //
  auth,
  homepage,
  city,
  category,
  tuan,
  institution,
  edi,
  knowledge,
  allowance,
  learning,
  salesman,
  groupGuide,
  book,
  recruitShare,
];

export default model;

import { edi } from '~/utils/api.ts';

const model = {
  namespace: 'edi',
  state: {
    skuList: [],
    token: '',
    sku: {},
    number: 1,
  },
  reducers: {
    init(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *getSku(_, { call, put }) {
      const resp = yield call(edi.getSku);
      if (resp.status === 0) {
        const skuList = resp.data;
        yield put({
          type: 'init',
          payload: {
            skuList,
          },
        });
      }
    },
    *login({ payload, callback }, { call, put }) {
      const resp = yield call(edi.login, payload);
      if (resp.status === 0) {
        const token = `Bearer ${resp.data.token}`;
        yield put({
          type: 'init',
          payload: {
            token,
          },
        });
        if (callback) {
          callback('登陆成功');
        }
      } else if (callback) {
        callback('登陆失败');
      }
    },
    *register({ payload, callback }, { call, put }) {
      const resp = yield call(edi.register, payload);
      if (resp.status === 0) {
        if (callback) {
          callback('注册成功');
        }
        yield put({
          type: 'login',
          payload,
        });
      } else if (callback) {
        callback('注册失败');
      }
    },
    *getSkuDetail({ payload }, { call, put }) {
      const resp = yield call(edi.getSkuDetail, { ...payload });
      if (resp.status === 0) {
        const sku = resp.data;
        yield put({
          type: 'init',
          payload: {
            sku,
          },
        });
      }
    },
    *createOrder({ payload, callback }, { call }) {
      const resp = yield call(edi.createOrder, payload);
      if (resp.status === 0) {
        if (callback) {
          callback({ status: resp.status, msg: '提交成功' });
        }
      } else if (callback) {
        callback({ status: resp.status, msg: '提交失败，请重试' });
      }
    },
  },
};

export default model;

import { group } from '~/utils/api.ts';

const model = {
  namespace: 'groupGuide',
  state: {
    groupList: [],
  },
  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *getGroupList({ payload }, { call, put }) {
      const response = yield call(group.getGroupList, payload);
      yield put({
        type: 'setState',
        payload: {
          groupList: response.data,
        },
      });
    },
  },
};

export default model;

import { tuan } from '~/utils/api.ts';

const model = {
  namespace: 'tuan',
  /**
   * sku详情页面
   * @params {Object} state - 参数
   * @params {number} state.status - sku接口状态
   * @params {number} state.cid - 品类ID
   * @params {number} state.type - 品类ID类型 1：一级标签，2:二级标签
   * @params {number} state.id - 商品ID
   * @params {number} state.page - sku订单列表页数
   * @params {number} state.total - sku订单列表总页数
   * @params {number} state.totalItems - sku订单列表总条数
   * @params {Array } state.orders - sku订单列表
   * @params {Object} state.customerService - 客服信息
   * @params {Object} state.product - 商品数据
   * @params {Array } state.recommentProducts - 推荐商品列表
   */
  state: {
    status: 0,
    id: '',
    type: '',
    typeStr: '',
    cid: '',
    customerService: {},
    product: {},
    recommentProducts: [],
    page: 1,
    orders: [],
    total: 1,
    totalItems: 0,
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *init({ payload }, { put }) {
      const { type } = payload;
      if (type && type === 1) {
        payload.typeStr = 'pc_index_categorys';
      } else if (type && type === 2) {
        payload.typeStr = 'categorys';
      }
      yield put({
        type: 'setState',
        payload,
      });
    },
    *getTuan(_, { call, put, select }) {
      const data = {
        product_id: yield select(state => state.tuan.id),
      };
      // 判断url中是否携带品类id
      const cid = yield select(state => state.tuan.cid);
      if (cid) {
        data.category_id = cid;
        data.category_type = yield select(state => state.tuan.typeStr);
      }
      const resp = yield call(tuan.getTuan, data);
      if (resp.status === 0) {
        const customerService = resp.customer_service || {};
        const product = resp.product || {};
        const recommentProducts = resp.recomment_products || [];
        yield put({
          type: 'setState',
          payload: {
            customerService,
            product,
            recommentProducts,
          },
        });
      } else {
        const { status } = resp;
        yield put({
          type: 'setState',
          payload: {
            status,
          },
        });
      }
    },
    *getOrders(_, { call, put, select }) {
      const data = {
        product_id: yield select(state => state.tuan.id),
        page: yield select(state => state.tuan.page),
      };
      const resp = yield call(tuan.getOrders, data);
      if (resp.status === 0) {
        const { orders } = resp;
        const total = resp.page_info.total_pages;
        const totalItems = resp.page_info.total_items;
        yield put({
          type: 'setState',
          payload: {
            orders,
            total,
            totalItems,
          },
        });
      }
    },
    *updateOrders({ payload }, { put }) {
      yield put({
        type: 'setState',
        payload,
      });
      yield put({ type: 'getOrders' });
    },
  },
};

export default model;

import { home } from '~/utils/api.ts';

const model = {
  namespace: 'index',
  state: {
    categorys: [],
    weikeList: [],
    experienceProducts: [],
    bigProducts: [],
  },
  reducers: {
    init(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *getStatic({ payload }, { call, put }) {
      const resp = yield call(home.getStatic, payload);
      if (resp.status === 0) {
        const weikeList = resp.pc_index_weikes;
        yield put({
          type: 'init',
          payload: {
            weikeList,
          },
        });
      }
    },
    *getSku(_, { call, put, select }) {
      const cityCode = yield select(state => state.city.currentCity.id);
      const resp = yield call(home.getSku, { city_code: cityCode });
      if (resp.status === 0) {
        const experienceProducts = resp.experience_products || [];
        const bigProducts = resp.big_products || [];
        const categorys = resp.pc_index_categorys || [];
        yield put({
          type: 'init',
          payload: {
            experienceProducts,
            bigProducts,
            categorys,
          },
        });
      }
    },
  },
};

export default model;

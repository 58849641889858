import { accounts } from '~/utils/api.ts';
import { data as apiData } from '~/utils/request.ts';

const model = {
  namespace: 'auth',
  state: {
    login: false,
    token: '',
    user: {},
  },
  effects: {
    *logout(_, { put, call }) {
      const resp = yield call(accounts.logout);
      if (resp.status === 0) {
        apiData.token = '';
        yield put({
          type: 'removeToken',
        });
      }
    },
    *login({ payload }, { put }) {
      const { token, user } = payload;
      yield put({
        type: 'saveUser',
        payload: {
          user,
        },
      });
      yield put({
        type: 'saveToken',
        payload: {
          token,
        },
      });
    },
  },
  reducers: {
    saveUser(state, { payload }) {
      const { user } = payload;
      return {
        ...state,
        login: true,
        user,
      };
    },
    removeToken(state) {
      return {
        ...state,
        token: '',
        login: false,
        user: {},
      };
    },
    saveToken(state, { payload }) {
      const { token } = payload;
      return {
        ...state,
        token,
      };
    },
  },
};

export default model;

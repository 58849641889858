import { book } from '~/utils/api.ts';

const model = {
  namespace: 'book',
  state: {
    playingId: null,
    bookList: [
      {
        id: 1,
        title: '待产早准备，分娩心不慌',
        audioLink: 'https://content-img.xindebaby.com/book_demo_1.mp3',
      },
      {
        id: 2,
        title: '如何做到顺产无侧切',
        audioLink: 'https://content-img.xindebaby.com/book_demo_2.mp3',
      },
      {
        id: 3,
        title: '胎动异常如何处理',
        audioLink: 'https://content-img.xindebaby.com/book_demo_3.mp3',
      },
      {
        id: 4,
        title: '产前就应知道的母乳常识',
        audioLink: 'https://content-img.xindebaby.com/book_demo_4.mp3',
      },
      {
        id: 5,
        title: '无痛分娩十大热门问答',
        audioLink: 'https://content-img.xindebaby.com/book_demo_5.mp3',
      },
      {
        id: 6,
        title: '待产包清单：物尽其用不浪费',
        audioLink: null,
      },
    ],
    commentsList: [],
  },
  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *postComment({ payload, callback }, { call }) {
      const resp = yield call(book.postComment, payload);
      if (resp.status === 0) {
        if (callback) {
          callback();
        }
      }
    },
    *getCommentsList({ payload, callback }, { call, put }) {
      const resp = yield call(book.getCommentsList, payload);
      if (resp.status === 0) {
        yield put({
          type: 'setState',
          payload: { commentsList: resp.data.comments },
        });
        if (callback) {
          callback();
        }
      }
    },
    *likeComment({ payload, callback }, { call }) {
      const resp = yield call(book.likeComment, payload);
      if (resp.status === 0) {
        if (callback) {
          callback();
        }
      }
    },
  },
};

export default model;
